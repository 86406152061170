import { AngularFireAuth } from '@angular/fire/auth';
import { DetailService } from './../../services/detail/detail.service';
import { ThemeService } from './../../services/theme/theme.service';
import { FormsModule } from '@angular/forms';
import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ModalController, LoadingController, NavParams, MenuController, AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { InterestService } from '../../services/interest/interest.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { GoogleAnalyticsService } from '../../services/googleAnalytics/google-analytics.service';
import { PaymentService } from '../../services/payment/payment.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit {

  newUser: any = {
    emailAddress: null,
    brand: null, 
    password: null,
    country: 'AU',
    agency: false,
    splitOption: null,
    firstName: null,
    lastName: null,
    businessName: null,
    phone: null,
    linkFacebook: null,
    linkInstagram: null,
    linkLinkedin: null,
    linkTwitter: null,
    cohorts: []
  };
  cohorts: any[] = [];

  agentUid: string | null = null;

  constructor(public modalCtrl: ModalController,
              public loadingCtrl: LoadingController,
              public location: Location,
              public themeService: ThemeService,
              public menuCtrl: MenuController,
              public router: Router,
              public platform: Platform,
              public navParams: NavParams,
              public afs: AngularFirestore,
              public paymentService: PaymentService,
              public afAuth: AngularFireAuth,
              public alertCtrl: AlertController,
              public auth: AuthService) {
                const agentUid = this.navParams.get('agent');
                if (agentUid) {
                  this.agentUid = agentUid;
                }
              }

  ngOnInit() {
    this.afs.collection('cohorts', ref => ref.orderBy('index', 'asc')).valueChanges({idField: 'id'}).subscribe(cohorts => {
      this.cohorts = cohorts;
    });
  }

  addToAgency(uid) {
    this.afs.doc('users/' + uid).valueChanges().pipe(first()).subscribe((user: any) => {
      if (user) {
        this.afs.doc('users/' + this.agentUid + '/projects/' + uid).set({
          customerEmail: user.email,
          projectUid: uid,
          time: new Date()
        });
        this.afs.doc('users/' + uid).set({
          agent: this.agentUid,
        }, {merge: true});
      }
    });
  }

  // ----------------- CREATE USER IN THE FIREBASE AUTHENTICATION SYSTEM ----------------- //
  async signup() {
    const loader = await this.loadingCtrl.create();
    loader.present();
    this.signupHandler()
      .then(async () => {
        await loader.dismiss();
        this.modalCtrl.dismiss();
      })
      .catch(err => {
        loader.dismiss();
      });
  }

  async signupHandler(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.paymentService.createFirebaseUser(this.newUser.emailAddress, this.newUser.password).subscribe(async (user) => {
        this.addToAgency(user.uid);
        await this.updateDetailsOnSignup(user.uid);
        resolve(null);
      }, (err) => {
        console.log(err);
        reject();
      });
    });
  }

  updateDetailsOnSignup(uid): Promise<any> {
    return new Promise ((resolve, reject) => {
      let updateReqSent = false;
      const sub = this.afs.doc('users/' + uid + '/customer/customer').valueChanges().subscribe((userdb: any) => {
        if (userdb !== null && userdb !== undefined) {
          if (updateReqSent === false) {
            updateReqSent = true;
            this.afs.doc("users/" + userdb.uid + '/customer/customer').update({
              brand: this.newUser.brand,
              countryCode: this.newUser.country,
              phone: this.newUser.phone,
              linkFacebook: this.newUser.linkFacebook,
              linkInstagram: this.newUser.linkInstagram,
              linkLinkedin: this.newUser.linkLinkedin,
              linkTwitter: this.newUser.linkTwitter,
            });
            this.afs.doc("users/" + userdb.uid + '/password/password').set({
              password: this.newUser.password
            });
            this.afs.doc('users/' + uid).update({
              phone: this.newUser.phone,
              firstName: this.newUser.firstName,
              lastName: this.newUser.lastName,
              businessName: this.newUser.businessName,
              cohorts: this.newUser.cohorts,
              linkFacebook: this.newUser.linkFacebook,
              linkInstagram: this.newUser.linkInstagram,
              linkLinkedin: this.newUser.linkLinkedin,
              linkTwitter: this.newUser.linkTwitter,
              brand: this.newUser.brand,
              axipAgency: this.newUser.agency,
              countryCode: this.newUser.country,
              splitOption: this.newUser.splitOption,
              password: this.newUser.password
            });
            sub.unsubscribe();
            resolve(null);
          }
        } 
      });
    });
  }

  // ----------------- METHOD FOR CREATE USER WITH NO AUTH ENTRY ----------------- //
  async newProspect() {
    const ld = await this.loadingCtrl.create({});
    ld.present();
    const customerIndex: any = {
      uid: null,
      firstName: this.newUser.firstName,
      lastName: this.newUser.lastName,
      businessName: this.newUser.businessName,
      phone: this.newUser.phone,
      email: this.newUser.emailAddress,
      brand: 'hospapp',
      subscribed: false,
      submitted: false,
      built: false,
      cancelled: false,
      countryCode: 'AU',
      building: false,
      axipStaff: false,
      axipAgency: this.newUser.agency,
      noAuth: true,
      agent: null,
      cohorts: this.newUser.cohorts,
      webDeployed: false,
      iosDeployed: false,
      androidDeployed: false,
      appReleasePendingReview: false,
      iosBuildPendingRelease: false,
      signupDate: new Date(),
      splitOption: null,
      stripeCustomerId: null,
    };
    const customerDetail: any = {
      uid: null,
      stripeCustomerId: null,
      firstName: this.newUser.firstName,
      lastName: this.newUser.lastName,
      businessName: this.newUser.businessName,
      phone: this.newUser.phone,
      email: this.newUser.emailAddress,
      signupDate: new Date(),
      brand: 'hospapp',
      countryCode: 'AU',
      linkFacebook: this.newUser.linkFacebook,
      linkInstagram: this.newUser.linkInstagram,
      linkLinkedin: this.newUser.linkLinkedin,
      linkTwitter: this.newUser.linkTwitter,
      freeText: null
    };
    if (this.newUser.emailAddress) {
      const checkSub = this.afs.collection('users', ref => ref.where('email', '==', this.newUser.emailAddress)).valueChanges().subscribe((users) => {
        checkSub.unsubscribe();
        if (users.length === 0) {
          this.afs.collection('users').add(customerIndex).then(data => {
            this.afs.doc('users/' + data.id).update({uid: data.id});
            this.addToAgency(data.id);
            this.afs.doc('users/' + data.id + '/customer/customer').set(customerDetail);
            ld.dismiss();
            this.modalCtrl.dismiss();
          });
        } else {
          ld.dismiss();
          const alert = this.alertCtrl.create({
            header: 'Error',
            message: 'User/prospect already exists. Cannot create another.',
            buttons: ['OK']
          });
          alert.then(a => {
            a.present();
          });
        }
      });
    } else {
      this.afs.collection('users').add(customerIndex).then(data => {
        this.afs.doc('users/' + data.id).update({uid: data.id});
        this.addToAgency(data.id);
        this.afs.doc('users/' + data.id + '/customer/customer').set(customerDetail);
        ld.dismiss();
        this.modalCtrl.dismiss();
      });
    }
  }

}
