import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs/Rx";
import { switchMap, first } from "rxjs/operators";
import { take } from "rxjs/operators";
import { fromPromise } from "rxjs/observable/fromPromise";
import { AlertController, LoadingController, MenuController, ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { PaymentService } from "../payment/payment.service";
import { Subscription } from "rxjs";
import { GoogleAnalyticsService } from "../googleAnalytics/google-analytics.service";
import { InterestService } from "../interest/interest.service";
import { ArchitectPlan } from "../../interfaces/architectPlan";
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

declare const google: any;
declare global {
  interface Window { axip: any; }
}

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user: Observable<any>;
  uid: string = null;
  readonly api = 'https://us-central1-' + environment.firebaseConfig.projectId + '.cloudfunctions.net/app';
  userIndexObject: any = {
    subscribed: false,
    built: false,
    details: {
      theme: false,
      appstore: false,
    },
    brand: null,
    axipStaff: false,
    uid: null,
    webDeployed: false,
    androidDeployed: false,
    iosDeployed: false,
    axipAgency: false
  };
  
  userCustomerObject: any = {
    firstName: null,
    lastName: null,
    businessName: null,
    email: null,
    stripeCustomerId: null,
    signupDate: null,
    brand: null,
    uid: null,
    phone: null,
    countryCode: null
  };

  userAgencyProfile: any = {
    businessName: null,
    email: null,
    professional: false,
    abn: null,
    bsb: null,
    accountNo: null,
    notifications: false
  };

  window;

  newUserAgency: boolean = false;
  newUserCountryCode: string = null;
  showAgencySignupSelector: boolean = false;

  customerSub: Subscription;
  cardDesc = "";

  cards: any[] = [];

  builds: any[] = [];
  subscriptions: any[] = [];
  purchases: any[] = []; 

  displayConfig: any = null;
  myPlans: ArchitectPlan[] = [];

  links: any[] = [];

  themeStarter: any = null;
  appstoreDetails: any = null;
  stripeDetails: any = null;

  terms: any[] = [];

  geocodeAlreadyChecked: boolean = false;

  attemptedRoute: string = null;

  constructor(public afs: AngularFirestore,
              public afAuth: AngularFireAuth,
              public loadingCtrl: LoadingController,
              public alertCtrl: AlertController,
              public modalCtrl: ModalController,
              @Inject("windowObject") window: Window,
              @Inject(DOCUMENT) private document: Document,
              public googleAnalyticsService: GoogleAnalyticsService,
              public pmt: PaymentService,
              public interestService: InterestService,
              public menuCtrl: MenuController,
              public http:HttpClient,
              public router: Router) {
    this.initializeUser();
  }

  initializeUser() {
    this.document.addEventListener('routeTo', (e: CustomEvent) => {
      e.preventDefault();
      if (e.detail.includes('http')) {
        
      } else {
        this.router.navigateByUrl(e.detail);
      }
    }, false);
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<any>("users/" + user.uid).valueChanges();
        } else {
          return of(null);
        }
      })
    );
    this.user.subscribe((user: any) => {
      if (user) {
        this.uid = user.uid;
        this.userIndexObject = user;
        this.afs.doc('users/' + this.uid + '/customer/customer').valueChanges().subscribe((customerObject: any) => {
          this.userCustomerObject = customerObject;
          if (customerObject && customerObject.stripeCustomerId) {
            this.pmt.getCustomer().subscribe(customer => {
              if (customer.sources.data.length > 0) {
                this.cardDesc = customer.sources.data[0].card.brand + " : *" + customer.sources.data[0].card.last4;
              }
            });
          }
          this.afs.doc("users/" + user.uid + "/starterTheme/starterTheme").valueChanges().subscribe(ts => {
            if (ts) { this.themeStarter = ts; }
          });
          this.afs.doc("users/" + user.uid + "/appstoreDetails/appstoreDetails").valueChanges().subscribe(asd => {
            if (asd) { this.appstoreDetails = asd; }
          });
          this.afs.collection('users/' + user.uid + '/plans').valueChanges({idField: 'id'}).subscribe((plans: ArchitectPlan[]) => {
            this.myPlans = plans;
          });
          this.afs.doc("users/" + user.uid + "/config/display").valueChanges().subscribe((dc: any) => {
            if (dc) { this.displayConfig = dc.config; }
          });
          this.afs.collection("users/" + user.uid + "/builds", ref => ref.orderBy('time', 'desc').limit(5)).valueChanges().subscribe(builds => {
            if (builds) { 
              this.builds = builds;
            }
          });
          this.afs.collection("users/" + user.uid + "/subscriptions").valueChanges().subscribe(subscriptions => {
            if (subscriptions) { this.subscriptions = subscriptions.sort((a: any, b: any) => {
              return b.subscriptionTime.toMillis() - a.subscriptionTime.toMillis();
            }); }
          });
          this.afs.collection('links', (ref) => ref.where('uid', '==', user.uid)).valueChanges({idField: 'id'}).subscribe(links => {
            if (links) {
              this.links = links;
            }
          });
          this.afs.collection("users/" + user.uid + "/purchases").valueChanges().subscribe(purchases => {
            if (purchases) { this.purchases = purchases.sort((a: any, b: any) => {
              return b.purchaseTime.toMillis() - a.purchaseTime.toMillis();
            }); }
          });
          this.afs.collection("users/" + user.uid + "/terms").valueChanges().subscribe(terms => {
            this.terms = terms;
          });
          if (this.userIndexObject.axipAgency) {
            this.afs.doc('users/' + user.uid + '/profile/profile').valueChanges().subscribe(profile => {
              if (profile) {
                this.userAgencyProfile = profile;
              }
            });
          }
          this.getCard();
        });
      } else {
        this.uid = null;
        this.userIndexObject = {
          subscribed: false,
          built: false,
          details: {
            theme: false,
            appstore: false,
          },
          brand: null,
          axipStaff: false,
          uid: null,
          webDeployed: false,
          androidDeployed: false,
          iosDeployed: false,
          axipAgency: false
        };
      }
    });
  }

  // functions for presentation of progress component
  isRoute(route) {
    return !(this.router.url === route);
  }
  getCard() {
    this.pmt.getCustomer().subscribe(c => {
      if (c) {
        this.cards = c.sources.data;
      }
    }, err => {
    });
  }

  getCurrentUser(): Promise<any> {
    return this.user.pipe(take(1)).toPromise();
  }
  getUserIdToken(): any {
    this.afAuth.currentUser.then(u => {
      return fromPromise(u.getIdToken());
    });
  }

  // logout function to be called from menus & navbar
  async logout(toggle: boolean) {
    const alert = await this.alertCtrl.create({
      header: 'Logout',
      mode: 'ios',
      message: 'Logout of your AxipApp account?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Logout',
          handler: async () => {
            this.googleAnalyticsService.sendEvent('logout');
            if (toggle) {
              await this.menuCtrl.toggle();
            }
            await this.afAuth.signOut();
            await this.router.navigate(["/"]);
          }
        }
      ]
    });
    alert.present();
  }

  // helper functions used in authentication & by users thorughout app
  async copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    const alert = await this.alertCtrl.create({
      header: 'Copied',
      mode: 'ios',
      message: 'url is in clipboard',
      buttons: ['OK']
    });
    await alert.present();
  }
  checkUserCountry() {
    // if (navigator.geolocation && this.uid === null && this.newUserCountryCode === null && this.geocodeAlreadyChecked === false) {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     const geocoder = new google.maps.Geocoder();
    //     const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    //     const request = {
    //       latLng: latlng
    //     };
    //     geocoder.geocode(request, (results, status) => {
    //       this.geocodeAlreadyChecked = true;
    //       if (status === google.maps.GeocoderStatus.OK) {
    //         if (results) {
    //           let result = results.filter(address => {
    //             if (address.types.includes('country')) {
    //               return true;
    //             } else {
    //               return false;
    //             }
    //           });
    //           if (result.length > 0) {
    //             result = result[0];
    //             const countryCode = result.address_components[0].short_name;
    //             if (countryCode === 'AU' || countryCode === 'NZ' || countryCode === 'US' || countryCode === 'CA' || countryCode === 'GB') {
    //               this.newUserCountryCode = countryCode;
    //             }
    //           }
    //         } else {}
    //       }
    //     });
    //   }); 
    // } 
  }

  changeEmail(uid:string,newEmail:string){
  return  this.http.post(`${this.api}/firebase/user/changeEmail`,{
      uid:uid,
      newEmail:newEmail
    })
  }

}

