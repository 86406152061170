import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoadingController, ToastController } from '@ionic/angular';
import { DetailService } from '../services/detail/detail.service';
import { take } from 'rxjs-compat/operator/take';
import { first } from 'rxjs-compat/operator/first';
import { tap, map } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';



@Injectable({
  providedIn: 'root'
})
export class SignedInGuard implements CanActivate {

  constructor(public router: Router,
              public detailService: DetailService,
              public loadingCtrl: LoadingController,
              public toastCtrl: ToastController,
              public auth: AuthService,
              public afAuth: AngularFireAuth) {}

  canActivate(route, state): any {
    if (this.detailService.uid) {
      return true;
    }
    return this.auth.user.pipe(
        map((user) => !!user),
        tap(async (loggedIn) => {
          if (!loggedIn) {
            this.detailService.attemptedRoute = route._routerState.url;
            this.router.navigate(['/signin']);
          } 
      })
    );
  }
  
}
