import { GoogleAnalyticsService } from './services/googleAnalytics/google-analytics.service';
import { Component } from '@angular/core';
import { Platform, MenuController, AlertController } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { flatten } from '@angular/compiler';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  uid: string = null;
  currentUrl: string = null;
  agency: boolean = false;
  staff: boolean = false;
  
  constructor(private platform: Platform,
              public afAuth: AngularFireAuth,
              public afs: AngularFirestore,
              public alertCtrl: AlertController,
              public menuCtrl: MenuController,
              public updates: SwUpdate,
              public googleAnalyticsService: GoogleAnalyticsService,
              public gtmService: GoogleTagManagerService,
              public router: Router) {
    this.updates.available.subscribe(async (event) => {
      const alert = await this.alertCtrl.create({
        header: 'Update',
        message: 'New version available',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {}
          }, {
            text: 'Update',
            handler: async () => {
              document.location.reload();
            }
          }
        ]
      });
      alert.present();
    });
    this.afAuth.authState.subscribe(data => {
      if (data && data.uid) {
        this.uid = data.uid;
        this.afs.doc('users/' + this.uid).valueChanges().subscribe((user: any) => {
          if (user && user.axipAgency) {
            this.agency = user.axipAgency;
          } else {
            this.agency = false;
          }
          if (user && user.axipStaff) {
            this.staff = true;
          } else {
            this.staff = false;
          }
        });
      } else {
        this.uid = null;
        this.agency = false;
        this.staff = false;
      }
    });
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     const gtmTag: any = {
    //       event: 'page',
    //       pageName: event.urlAfterRedirects
    //     };
    //     this.currentUrl = event.urlAfterRedirects;
    //     this.gtmService.pushTag(gtmTag);
    //     if (!this.uid) {
    //       gtmTag.time = new Date();
    //       this.googleAnalyticsService.stashedLogs.push(gtmTag);
    //     } else {
    //       gtmTag.time = new Date();
    //       this.googleAnalyticsService.saveLog(gtmTag);
    //     }
    //   }
    // });
  }

  // logout function to be called from menus & navbar
  async logout() {
    const alert = await this.alertCtrl.create({
      header: 'Logout',
      message: 'Logout of your AxipApp account?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Logout',
          handler: async () => {
            await this.afAuth.signOut();
            await this.router.navigate(["/signin"]);
          }
        }
      ]
    });
    alert.present();
  }
}
