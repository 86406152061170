import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-media-selector',
  templateUrl: './media-selector.page.html',
  styleUrls: ['./media-selector.page.scss'],
})
export class MediaSelectorPage implements OnInit {

  type: string = null;
  media: any[] = [];

  showFolders: boolean = false;
  folders: string[] = [];
  filterFolder: string = null;

  constructor(public modalCtrl: ModalController,
              public navParams: NavParams,
              public afs: AngularFirestore) {
                this.type = this.navParams.get('type');
                this.afs.collection('media').valueChanges().subscribe(data => {
                  this.media = data;
                  this.folders = [];
                  this.media.forEach(mediaItem => {
                    if (mediaItem.folder && !this.folders.includes(mediaItem.folder)) {
                      this.folders.push(mediaItem.folder);
                    }
                  });
                });
              }

  ngOnInit() {
  }

  returnIcon(type) {
    if (type === 'text') {
      return 'chatbubbles';
    } else if (type === 'audio') {
      return 'mic'; 
    } else if (type === 'video') {
      return 'videocam'; 
    } else if (type === 'image') {
      return 'images'; 
    }
  }

  select(url) {
    this.modalCtrl.dismiss(url, 'selected');
  }

}
