import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {Charge, Customer, Source, StripeObject, SubscriptionPlan} from "../../interfaces/stripe";
import {fromPromise} from "rxjs/observable/fromPromise";
import {switchMap} from "rxjs/operators";
import { environment } from '../../../environments/environment';

declare var Stripe;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  readonly api = 'https://us-central1-' + environment.firebaseConfig.projectId + '.cloudfunctions.net/app';
  public stripe = Stripe(environment.stripe_publishable);
  elements: any;

  taxRates = [];

  constructor(public http: HttpClient) {
    this.elements = this.stripe.elements();
  }

  // FIREBASE AUTH OPERATIONS
  createFirebaseUser(email: string, password: string): Observable<any> {
    const url = `${this.api}/firebase/user/create`;
    return this.http.post<any>(url, {email, password});
  }
  deleteFirebaseUser(uid: string): Observable<any> {
    const url = `${this.api}/firebase/user/delete`;
    return this.http.post<any>(url, {uid: uid});
  }

  // CUSTOMER
  getCustomer(uid?: string): Observable<Customer> {
      const url = `${this.api}/customer/`;
      return this.http.post<Customer>(url, {uid});
  }
  createCustomer(email: string, uid: string): Observable<Customer> {
      const url = `${this.api}/customer/create`;
      return this.http.post<Customer>(url, {email, uid});
  }

  // CHARGES
  getCharges(): Observable<any> {
      const url = `${this.api}/charges/`;
      return this.http.get<StripeObject>(url);
  }
  getChargesSubordinate(uid): Observable<any> {
      const url = `${this.api}/charges/subordinate`;
      return this.http.post<any>(url, {uid});
  }
  
  chargeSource(sourceId: any, amount: number, currency: string, uid) {
      const url = `${this.api}/charges/`;
      const observable: Observable<any> = this.http.post<any>(url, {
          amount,
          currency: currency,
          sourceId: sourceId,
          uid: uid
      });
      return observable;
  }

  // SOURCES
  attachSource(card: any, uid?: string): Observable<Source> {
      const url = `${this.api}/sources/`;
      return fromPromise<any>( this.stripe.createSource(card) ).pipe(
          switchMap((data: any) => {
              return this.http.post<Source>(url, { sourceId: data.source.id, uid: uid });
          })
      );
  }
  detachSource(source: Source, uid?: string) {
      const url = `${this.api}/source/remove`;
      const observable: Observable<any> = this.http.put<any>(url, { sourceId: source.id, uid: uid });
      return observable;
  }

  // SUBSCRIPTIONS
  attachSubscription(sourceId: string, planId: string, taxRatesId: string, userId: string, trialDays: number): Observable<SubscriptionPlan> {
      taxRatesId = [taxRatesId] as any;
      const url = `${this.api}/subscriptions`;
      return this.http.post<SubscriptionPlan>(url, { sourceId, planId, taxRatesId, userId, trialDays });
  }
  editSubscription(subscriptionId: string, newPlanId: string, oldPlanId: string, taxRatesId: any[], userId: string): Observable<SubscriptionPlan> {
      const url = `${this.api}/subscriptions/edit`;
      return this.http.put<SubscriptionPlan>(url, { subscriptionId, newPlanId, oldPlanId, taxRates: taxRatesId, userId });
  }
  cancelSubscription(subscriptionId: string): Observable<SubscriptionPlan> {
      const url = `${this.api}/subscriptions/cancel`;
      return this.http.put<SubscriptionPlan>(url, { subscriptionId });
  }
  getSubscriptions(customerId: string): Observable<any> {
      console.log(customerId);
      const url = `${this.api}/subscriptions/get`;
      return this.http.post<any>(url, { customerId });
  }

  // PRODUCTS
  createProduct(name: string): Observable<any> {
    const url = `${this.api}/product/create`;
    return this.http.post<any>(url, { name });
  }
  getProduct(id: string): Observable<any> {
    const url = `${this.api}/product/get`;
    return this.http.post<any>(url, { id });
  }
  getAllProducts(): Observable<any> {
    const url = `${this.api}/products/getAll`;
    return this.http.post<any>(url, {});
  }

  // PLANS
  createPlan(product: string, amount: number, interval: string, currency: string): Observable<any> {
    const url = `${this.api}/plan/create`;
    return this.http.post<any>(url, { product, amount, interval, currency });
  }
  getPlan(id: string): Observable<any> {
    const url = `${this.api}/plan/get`;
    return this.http.post<any>(url, { id });
  }
  getAllPlans(): Observable<any> {
    const url = `${this.api}/plans/getAll`;
    return this.http.post<any>(url, {});
  }

  // TAX
  createTax(displayName, description, jurisdication, percentage): Observable<any> {
    const url = `${this.api}/tax/add`;
    return this.http.post<any>(url, {displayName, description, jurisdication, percentage});
  }
  deactivateTax(id): Observable<any> {
    const url = `${this.api}/tax/deactivate`;
    return this.http.post<any>(url, {id});
  }
  getAllTaxRates(): Observable<any> {
    const url = `${this.api}/tax/get`;
    return this.http.post<any>(url, {});
  }

  // EMAIL SEQUENCE MANAGEMENT
  addEmailSequence(sid, uid): Observable<any> {
    const url = `${this.api}/add/sequence`;
    return this.http.post<any>(url, {
      sid: sid,
      uid: uid
    });
  }

  initializeTaxRates() {
    this.getAllTaxRates().subscribe(rates => {
      this.taxRates = rates.data;
    }, err => {
      console.log(err);
    });
  }

  returnCurrencyFromCountry(country) {
    if (country === 'AU') {
      return 'aud';
    } else if (country === 'NZ') {
      return 'nzd';
    } else if (country === 'US') {
      return 'usd';
    } else if (country === 'CA') {
      return 'cad';
    } else if (country === 'GB') {
      return 'gbp';
    }
  }
  returnTaxFromCountry(country) {
    const rates = this.taxRates.filter(rate => {
      if (rate.jurisdiction === country) {
        return true;
      } else {
        return false;
      }
    });
    if (rates.length > 0) {
      return rates[0];
    } else {
      return null;
    }
  }

  // COPY CONFIG
  copyConfig(uid) {
    const url = `${this.api}/copyconfig`;
    return this.http.post<any>(url, { uid });
  }

}
