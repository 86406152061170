import { first } from 'rxjs/operators';
import { AppTestimonial } from './../../interfaces/appTestimonial';
import { Injectable } from '@angular/core';
import { AppProduct } from '../../interfaces/appProduct';
import { AppScreenshot } from '../../interfaces/screenshot';
import { AppBenefit } from '../../interfaces/appBenefit';
import { AppFeature } from '../../interfaces/appFeature';
import { AngularFirestore } from '@angular/fire/firestore';
import {cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InterestService {

  interest: string = null;
  splitId: string = null;
  set: boolean = false;
  brandObject: AppProduct = {
    index: null,
    name: null,
    business: [],
    description: '',
    icon: null,
    introHeading: null,
    introText: null,
    upfrontPricingId: null,
    subscriptionPricingId: null,
    upfrontPricing: null,
    subscriptionPricing: null,
    benefits: [],
    testimonials: [],
    demoVideo: null,
    learnMoreVideo: null,
    image: null,
    showPlatformBlock: true,
    features: [],
    screenshots: [],
    splitOption: null,
    showInIndex: true,
    heading: null,
    subheading: null,
    showMenu: true,
    showGuarantees: true,
    showPerfectFor: true,
    ctaHeading: null,
    ctaText: null,
    cta: null,
    themingText: null,
  };
  blankProduct: AppProduct = null;

  appProducts: AppProduct[] = [
    {
      name: 'HospApp',
      business: ['cafes', 'restaurants', 'bars'],
      description: '',
      icon: 'cafe',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhospitality1593416846351?alt=media&token=472e74e6-6d25-4507-a59f-c1638d4e6e84',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Hospitality Apps',
      subheading: 'by AxipApp',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      showMenu: false,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'ClinicApp',
      business: ['clinics', 'counselors', 'medical practices'],
      description: '',
      icon: 'flag',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fclinic1593416936423?alt=media&token=6b8d9650-2f52-4646-9cc2-9c9754e6f62c',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Clinics',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'SalonApp',
      business: ['salons', 'makeup artists', 'barbers'],
      description: '',
      icon: 'cut',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fsalon1593416804127?alt=media&token=4df253ff-e2da-421f-a512-924479637982',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Salons',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'GymApp',
      business: ['gyms', 'personal trainers', 'bootcamps'],
      description: '',
      icon: 'body',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgym1593416865696?alt=media&token=0602d3f9-cf75-4520-8c6c-d3df619ec4db',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Gyms',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'RetailApp',
      business: ['retail stores', 'florists', 'service business\''],
      description: '',
      icon: 'home',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fretail1593416782849?alt=media&token=c42f20a0-4c6b-4391-9864-ec2a388921e7',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Retail Apps',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'ECommApp',
      business: ['brands', 'products', 'manufacturers'],
      description: '',
      icon: 'cart',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fprofessional1593416816969?alt=media&token=58b4a2ea-aa3e-4e6e-acf8-7b28cd05d294',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Ecommerce Apps',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'ProApp',
      business: ['professionals', 'creatives', 'instructors'],
      description: '',
      icon: 'create',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fpublish1593416831909?alt=media&token=20bcd5d3-f270-465f-92d8-da9cda0c11ec',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Professionals',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'GroupApp',
      business: ['social groups', 'sporting teams', 'professional clubs'],
      description: '',
      icon: 'people',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgroups1593416908929?alt=media&token=982a2c8b-b1e4-4773-9c66-44b6629d560c',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Groups',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'CharityApp',
      business: ['charities', 'fundraisers', 'nfp\'s'],
      description: '',
      icon: 'heart',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fcharity1593416923469?alt=media&token=08b6212e-5f0c-4488-8dc2-18320b999260',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Charities',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
    {
      name: 'PublishApp',
      business: ['blogs', 'magazines', 'newspapers'],
      description: '',
      icon: 'bookmark',
      introHeading: 'What is AxipApp?',
      introText: 'AxipApp is \'The App Builder\'. Using only this website you can build yourself a powerful custom app in less than 5 minutes.',
      upfrontPricingId: 'sbKOKWnDbBjKmk1pNh9I',
      subscriptionPricingId: 'dzpKIRG9vdkNq7vwmppk',
      upfrontPricing: null,
      subscriptionPricing: null,
      benefits: ['br01', 'ea01', 'da01', 'cu01', 'to01', 'mo01', 'se01', 'mb01', 'tb01', 'pu01', 'lg01', 'su01', 'co01', 'in01', 'fq01', 'rw01', 'sc01'],
      testimonials: ['sd01', 'sh01', 'tv01', 'sd02', 'sh02', 'sd03', 'sh03'],
      demoVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhome1578362364669?alt=media&token=c2f65ded-30e5-4407-b640-c8dfef4e97b3',
      learnMoreVideo: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmyapp1578362419569?alt=media&token=cc769d5d-206c-4269-a806-3fd17824d174',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fcreative1593416895943?alt=media&token=b714173d-b980-4db8-8d36-9b0e8c6534d4',
      showPlatformBlock: true,
      features: ['br01', 'cr01', 'st01', 'bk01', 'sub01', 'pn01', 'rw01', 'loc01', 'sp01', 'co01', 'nt01', 'sq01', 'rm01', 'fm01', 'ud01', 'op01', 'an01', 'int01', 'trn01', 'cus01'],
      screenshots: ['vita01', 'axip00', 'dbc01', 'metro01', 'axip01', 'naw01', 'spareya01', 'inspwell01'],
      splitOption: null,
      showInIndex: true,
      heading: 'Custom Apps for Publishers',
      subheading: 'by AxipApp',
      showMenu: true,
      showGuarantees: true,
      showPerfectFor: true,
      ctaHeading: 'Learn more or get started today',
      ctaText: 'Create a free account to see video lessons, ask questions via support chat & build your app.',
      cta: 'Free Account',
      themingText: 'Make your app represent your brand using our drag & drop theme editor.',
      index: null,
    }, 
  ];
  displayIndustries: any[] = [];
  currentIndustry: string = null;

  appProductsIndex: AppProduct[] = [];

  appFeatures: AppFeature[] = [
    {
      showInIndex: true,
      code: 'br01',
      icon: 'color-palette',
      name: 'Branding',
      description: 'Stay on customers minds. Put your brand front & center on all devices.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'cr01',
      icon: 'star',
      name: 'Credibility',
      description: 'Until now, only the largest companies offered customers apps. Now you can too.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'st01',
      icon: 'basket',
      name: 'Store',
      description: 'Conveniently sell more to customers quicker & easier using your in-app store',
      index: null,
    },
    {
      showInIndex: true,
      code: 'bk01',
      icon: 'calendar',
      name: 'Bookings',
      description: 'Receive more bookings & manage them more efficiently with in-app bookings.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'sub01',
      icon: 'repeat',
      name: 'Subscriptions',
      description: 'Grow your recurring revenue. Conveniently & securely charge customer subscriptions.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'pn01',
      icon: 'notifications',
      name: 'Notifications',
      description: 'Reach customers at exactly the right time with targeted push notifications & emails. ',
      index: null,
    },
    {
      showInIndex: true,
      code: 'rw01',
      icon: 'trophy',
      name: 'Rewards',
      description: 'Keep bringing customers back with versatile reward, level & discount programs.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'loc01',
      icon: 'pin',
      name: 'Locations',
      description: 'Conveniently display multiple store locations & opening hours for customers.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'sp01',
      icon: 'chatbubbles',
      name: 'Support',
      description: 'Consolidate support enquiries. Improve staff efficiency with in-app customer chat.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'co01',
      icon: 'list',
      name: 'Content',
      description: 'Keep your customers engaged by publishing articles & videos in your app.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'nt01',
      icon: 'clipboard',
      name: 'Notes',
      description: 'Allow customers to conveniently keep notes in-app. Then use those notes to close sales.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'sq01',
      icon: 'funnel',
      name: 'Sequences',
      description: 'Create more engagement with guided in-app experiences such as; tours or exercises.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'rm01',
      icon: 'alarm',
      name: 'Reminders',
      description: 'Help customers remember appointments & reach their goals with versatile reminders.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'fm01',
      icon: 'checkmark-circle',
      name: 'Forms',
      description: 'Collect data more conveniently. From inductions to enquiries or safety forms.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'ud01',
      icon: 'person-circle',
      name: 'Data',
      description: 'Track progress & collect comprehensive data on your customers for marketing. ',
      index: null,
    },
    {
      showInIndex: true,
      code: 'rp01',
      icon: 'checkbox',
      name: 'Reports',
      description: 'Never stop improving. Generate reports for business analysis & financial management.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'op01',
      icon: 'business',
      name: 'Operations',
      description: 'Simplify operations for staff. Use your app behind the counter for every aspect of your business.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'an01',
      icon: 'analytics',
      name: 'Analytics',
      description: 'Track & optimize how customers use your app with Google Analytics.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'trn01',
      icon: 'school',
      name: 'Training',
      description: 'Easy app setup & management. Staff training videos & unlimited free technical support.',
      index: null,
    },
    {
      showInIndex: true,
      code: 'cus01',
      icon: 'code',
      name: 'Customizable',
      description: 'Anything is possible. Anyone can write custom code to expand your apps features ap any time.',
      index: null,
    },
    // {
    //     code: 'sup'
    //   icon: 'chatboxes',
    //   name: 'Support',
    //   description: 'Unlimited technical support for staff using convenient support chat within your app.'
    // },
  ];
  featuresIndex: AppFeature[] = [];
  selectedFeature: AppFeature = null;

  appBenefits: AppBenefit[] = [
    {
      code: 'br01',
      icon: 'bulb',
      title: 'Stay On Customers Minds',
      description: 'Put your brand front & center on customers phones, tablets & computers. Your colors, logos & products displayed more conveniently than ever.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fdemonstratetwo1593419471168?alt=media&token=7d4227b0-9b90-4e2f-8ee0-d39448d7f45a',
      index: null,
    },
    {
      code: 'ea01',
      icon: 'school',
      title: 'Easy Setup, Free Support',
      description: 'Staff can train themselves to setup & manage your app with extensive video lessons. Plus unlimited free support chat based 100% in Melbourne when needed.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fchat1593419358447?alt=media&token=22da419c-e8dd-43b6-9128-a4ec4593152d',
      index: null,
    },
    {
      code: 'da01',
      icon: 'list-box',
      title: 'Gather the Right Data',
      description: 'Use your app to track order & booking trends. Generate ideas to keep your business moving in the right direction.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fdata1593419553229?alt=media&token=e732a5e2-a07c-4750-81b6-ef6ead2bdd64',
      index: null,
    },
    {
      code: 'cu01',
      icon: 'people',
      title: 'Know Your Customers',
      description: 'Collect customer data & monitor signups to track your growing customer base. Track user trends to improve & grow your business.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fcrowd1593419573333?alt=media&token=7db00e1a-6c30-412d-b50a-0242a30db4b7',
      index: null,
    },
    {
      code: 'sc01',
      icon: 'thumbs-down',
      title: 'Stay in Control',
      description: 'Social media is good for finding new customers. But don\'t let facebook control your connection to your customers. Bring customers to your app where you make the rules.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fleader1593419511135?alt=media&token=fcf7bd70-18f9-4bf6-9da2-8ddbb919833b',
      index: null,
    },
    {
      code: 'pu01',
      icon: 'notifications',
      title: 'Push Ad’s That Work',
      description: 'Reach customers at exactly the right time with offers & reminders by sending unlimited push notifications from within your app.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fdemonstrate1593419429593?alt=media&token=dc0d2964-6340-440a-b300-963def953547',
      index: null,
    },
    {
      code: 'to01',
      icon: 'cart',
      title: 'Seamless Ordering',
      description: 'Use your in-app store to make ordering more convenient & take more orders. Then automatically notify customers of order progress & improve satisfaction.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fpurchases1593419489814?alt=media&token=8bd1f40a-ec50-486e-928c-8f7a6ba94572',
      index: null,
    },
    {
      code: 'mo01',
      icon: 'alarm',
      title: 'Improve Order Efficiency',
      description: 'Improve staff efficiency & reduce embarrassing mistakes. Seamlessly track & manage orders as they are processed & completed for customers.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fwarehouse1593419292671?alt=media&token=527c9b98-4e8b-4252-ad10-b4ff826b44d3',
      index: null,
    },
    {
      code: 'tb01',
      icon: 'calendar',
      title: 'Take More Bookings',
      description: 'Increase bookings & revenue by making booking more convenient for customers. In-app bookings are supported for virtually anything bookable.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fpurchases1593419489814?alt=media&token=8bd1f40a-ec50-486e-928c-8f7a6ba94572',
      index: null,
    },
    {
      code: 'mb01',
      icon: 'list',
      title: 'More Efficient Booking',
      description: 'Stop wasting time managing booking calendars & class capacities. Your app can do this automatically so you can stay focussed on your customers.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fbookings1593419413614?alt=media&token=24d4ad99-054a-4f42-8b68-1e7e3fab1afc',
      index: null,
    },
    {
      code: 'su01',
      icon: 'repeat',
      title: 'Convenient Subscriptions',
      description: 'Make recurring payments simple & secure for customers. Memberships made simpler than ever for staff & customers.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fpurchases1593419489814?alt=media&token=8bd1f40a-ec50-486e-928c-8f7a6ba94572',
      index: null,
    },
    {
      code: 'rw01',
      icon: 'trophy',
      title: 'Bring Customers Back',
      description: 'Setup unlimited rewards programs to keep your customers coming back. Collect stamps & claim rewards either in-app or instore.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fcrowd1593419573333?alt=media&token=7db00e1a-6c30-412d-b50a-0242a30db4b7',
      index: null,
    },
    {
      code: 'co01',
      icon: 'bookmarks',
      title: 'Engage with Content',
      description: 'Publish articles & videos in-app to keep customers thinking & talking about your business. Bring them back to the app to make more orders & bookings.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fvideo1593419317834?alt=media&token=5c3e71a0-4a0a-4517-80a5-cbaf93634047',
      index: null,
    },
    {
      code: 'fq01',
      icon: 'clipboard',
      title: 'Reduce Staff Workload',
      description: 'Instead of answering the same questions over and over again publish FAQ’s for all to see as content articles & videos in-app. ',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fteam1593419336308?alt=media&token=eb1faef5-3aa2-4694-8ac5-f7013fe57249',
      index: null,
    },
    // {
    //   code: 'in01',
    //   icon: 'map',
    //   title: 'Display Essential Info.',
    //   description: 'Prominently display your location(s) & opening hours in-app.',
    // },
    {
      code: 'se01',
      icon: 'chatbubbles',
      title: 'Simple Support Enquiries',
      description: 'Stop taking support enquiries from dozens of different sources. Make your app the convenient single point of contact for customers & efficiently manage with fewer staff.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fchat1593419358447?alt=media&token=22da419c-e8dd-43b6-9128-a4ec4593152d',
      index: null,
    },
    {
      code: 'lg01',
      icon: 'trending-up',
      title: 'Limitless Growth',
      description: 'Your app can grow as quick as you can. Supporting unlimited store locations & all major currencies for the same low prices.',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgrow1593419541010?alt=media&token=53b06b7e-57d9-43b5-a34c-8ca71495efcf',
      index: null,
    },
  ];

  appTestimonials: AppTestimonial[] = [
    {
      code: 'sd01',
      name: 'Sam Dwyer',
      title: 'Director',
      business: 'Dwyers Boxing Club',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_sam1593427468277?alt=media&token=9496f01c-489a-495b-874c-a5cb8b6ee022',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_dbc1593427599331?alt=media&token=aa4fc3c7-b49f-4dc2-b199-3efe36335327',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_dbc1593427662741?alt=media&token=8dd3fa46-6260-4938-8121-d26453247dcd',
      p1: 'We love how easy (our app) is to use. I have my most junior staff member handle the whole app it is that easy. From classes to personal training. Memberships & push notifications.',
      index: null,
      p2: '',
      brands: []
    },
    {
      code: 'sh01',
      name: 'Sarah Hanley',
      title: 'Director',
      business: 'Nawaehi',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_sarah1593427495440?alt=media&token=d39e142d-5057-45b4-9661-4005630e00e8',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_nawaehi1593427631656?alt=media&token=e302e1fb-aa93-4a66-8930-3b298403da3c',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_nawaehi1593427683624?alt=media&token=6eba7557-6e99-4f82-afea-cf40b6fe4aad',
      p1: 'Scheduling clients is a huge part of my day. One on one time with my clients doesn’t allow me to answer phone calls or respond to messages and emails promptly, enter my saviour virtual receptionist, my app. ',
      p2: 'I have now gained back 7+ hours of my work week, meaning more time with clients, meaning greater income! It\'s a no brainer.',
      index: null,
      brands: ['clinicapp']
    },
    {
      code: 'tv01',
      name: 'Toni Vorenas',
      title: 'Director',
      business: 'Metro Cafe',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_toni1593427532892?alt=media&token=0d78c0fe-84c2-4a01-9929-e23d22f3bb4c',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_metro1593427575456?alt=media&token=aa712d22-a775-4f87-aa44-8349933a44c5',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_metro1593427708450?alt=media&token=3f2f2c33-b9e4-4c72-b427-fefddc4b3329',
      p1: 'The features go above & beyond simply ordering. Our app includes so much for now & for us to grow into. Including; reward programs, table bookings, feedback, catering enquries, reports, analytics & marketing tools.',
      index: null,
      p2: '',
      brands: []
    },
    {
      code: 'sd02',
      name: 'Sam Dwyer',
      title: 'Director',
      business: 'Dwyers Boxing Club',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_sam1593427468277?alt=media&token=9496f01c-489a-495b-874c-a5cb8b6ee022',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_dbc1593427599331?alt=media&token=aa4fc3c7-b49f-4dc2-b199-3efe36335327',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_dbc1593427662741?alt=media&token=8dd3fa46-6260-4938-8121-d26453247dcd',
      p1: 'Axipapp has been a game changer for our business. We grew at 97% last year and our app was a huge part of that.',
      index: null,
      p2: '',
      brands: []
    },
    {
      code: 'sh02',
      name: 'Sarah Hanley',
      title: 'Director',
      business: 'Nawaehi',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_sarah1593427495440?alt=media&token=d39e142d-5057-45b4-9661-4005630e00e8',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_nawaehi1593427631656?alt=media&token=e302e1fb-aa93-4a66-8930-3b298403da3c',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_nawaehi1593427683624?alt=media&token=6eba7557-6e99-4f82-afea-cf40b6fe4aad',
      p1: 'Not only do I love my app, but so do my clients. If only there was a recording device in my clinic rooms, their feedback but it usually goes; “Its so easy to use, even for someone like me"',
      index: null,
      p2: '',
      brands: ['clinicapp']
    },
    {
      code: 'sd03',
      name: 'Sam Dwyer',
      title: 'Director',
      business: 'Dwyers Boxing Club',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_sam1593427468277?alt=media&token=9496f01c-489a-495b-874c-a5cb8b6ee022',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_dbc1593427599331?alt=media&token=aa4fc3c7-b49f-4dc2-b199-3efe36335327',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_dbc1593427662741?alt=media&token=8dd3fa46-6260-4938-8121-d26453247dcd',
      p1: 'Our customers love the app. They don\'t have to show up and hope the class isn\'t full any more. ',
      index: null,
      p2: '',
      brands: []
    },
    {
      code: 'sh03',
      name: 'Sarah Hanley',
      title: 'Director',
      business: 'Nawaehi',
      avatar: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_avatar_sarah1593427495440?alt=media&token=d39e142d-5057-45b4-9661-4005630e00e8',
      image: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_cover_nawaehi1593427631656?alt=media&token=e302e1fb-aa93-4a66-8930-3b298403da3c',
      icon: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftestimonial_icon_nawaehi1593427683624?alt=media&token=6eba7557-6e99-4f82-afea-cf40b6fe4aad',
      p1: 'Now I\'m not the most technologically skilled entrepreneur, but whenever I get stuck the videos are easy to understand, clear and because everything is so simple to navigate, I often figure everything out myself quite quickly.',
      index: null,
      p2: '',
      brands: ['clinicapp']
    },
  ];

  testimonialOnePerOwner: any[] = [];

  screenshots: AppScreenshot[] = [
    {
      code: 'vita01',
      brand: 'hospapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2FScreenshot7VitaNew1591923165601?alt=media&token=c4dd3266-7bcb-47a2-a2a3-df282c74b3c9',
      name: 'Vita',
      link: 'https://vitaproductive.web.app',
      index: null,
    },
    // {
    //   code: 'axip00',
    //   brand: 'hospapp',
    //   url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot1axip1570665039811?alt=media&token=d00bd28c-2b9c-4505-b912-822a8ce07820',
    //   name: 'AxipApp',
    //   link: 'https://hosptemplate.web.app'
    // },
    {
      code: 'dbc01',
      brand: 'hospapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot16dbc1591924760210?alt=media&token=ecd63d27-f86f-4173-a030-e245a72279bc',
      name: 'Dwyers Boxing Club',
      link: 'https://dwyersboxing.web.app',
      index: null,
    },
    {
      code: 'sf01',
      brand: 'gymapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot10sims1591923528252?alt=media&token=7c63b9f6-ce70-4b53-80bd-76b429300378',
      name: 'Sims Fitness',
      link: 'https://simsfitness-axip.web.app',
      index: null,
    },
    {
      code: 'metro01',
      brand: 'hospapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot11metro1591923760144?alt=media&token=33d91261-04a8-4e76-bab8-5b386894e565',
      name: 'Metro',
      link: 'https://metro-5290.web.app',
      index: null,
    },
    {
      code: 'naw01',
      brand: 'clinicapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshotnawaehinew1591923934759?alt=media&token=4e331716-89e2-4116-b3c7-dc467eb5096c',
      name: 'Nawaehi',
      link: 'https://nawaehi-93ef9.web.app',
      index: null,
    },
    {
      code: 'spareya01',
      brand: 'hospapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot15spareya1591924569421?alt=media&token=5b482f4a-7780-48bb-b01a-d3b31a5d3ddb',
      name: 'Spare Ya Change',
      link: 'https://spareyachange0000.web.app',
      index: null,
    },
    {
      code: 'cnfsn01',
      brand: 'hospapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot12confsn1591924068352?alt=media&token=1a902fb2-109c-4826-b7c8-1d57aafce221',
      name: 'Confession Cafe',
      link: 'https://confessionco-axip.web.app/',
      index: null,
    },
    {
      code: 'bba01',
      brand: 'gymapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot14bba1591924262810?alt=media&token=b632b3ca-e820-4737-b4e3-eae44b31e2c5',
      name: 'Back Belt Academy',
      link: 'https://blackbeltacademy-axip.web.app/#/home',
      index: null,
    },
    {
      code: 'inspwell01',
      brand: 'clinicapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot8inspired1591923372149?alt=media&token=b7d3be6c-4351-452f-a753-cc0b9bd41c8a',
      name: 'Inspired Wellbeing',
      link: 'https://inspiredwellbeing-axip.web.app',
      index: null,
    },
    {
      code: 'gub01',
      brand: 'hospapp',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fscreenshot20guber1591924991683?alt=media&token=368ddc63-9f2f-4fde-ab03-2abce9b13418',
      name: 'Guber Gulps',
      link: 'https://gubergulps-axip.web.app/',
      index: null,
    },
  ];

  integrations: any[] = [
    {
      name: 'Stripe',
      explanation: 'Process payments & subscriptions from your app.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fstripe1591145748630?alt=media&token=fce6d1aa-d369-4ed7-9ece-024931980f96',
      category: 'payments'
    },
    {
      name: 'Twillio',
      explanation: 'Send sms campaigns from your app.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftwilio1591145804939?alt=media&token=92553464-5339-4437-b676-a9a7e0af356c',
      category: 'phone'
    },
    {
      name: 'Sendgrid',
      explanation: 'Send email campaigns from your app.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fsendgrid1596686928941?alt=media&token=40215b54-e020-4f2a-87dd-7a0d899fc126',
      category: 'email'
    },
    {
      name: 'Amazon',
      explanation: 'Manage your Amazon account.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Famazon1591146066732?alt=media&token=ce090720-39c8-4ac6-9108-7314609f9726',
      category: 'business'
    },
    {
      name: 'Asana',
      explanation: 'Add tasks & projects to your Asana.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fasana1591146050263?alt=media&token=2f7a7b94-9a4d-4be1-aefc-81389ac8f846',
      category: 'tasks'
    },
    {
      name: 'Azure',
      explanation: 'Manage your Azure account.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fazure1591146082636?alt=media&token=a0693bed-0ed7-4b31-b92e-acf019446684',
      category: 'business'
    },
    {
      name: 'Calendarly',
      explanation: 'Add to your Calendarly.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fcalendarly1591145242991?alt=media&token=a8c63edd-d1b1-48cc-b482-f1ac5b5bf821',
      category: 'calendars'
    },
    {
      name: 'Dropbox',
      explanation: 'Manage your Dropbox files.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fdropbox1591145291135?alt=media&token=928b380f-d234-4440-a67c-b5faa4979bac',
      category: 'storage'
    },
    {
      name: 'Facebook',
      explanation: 'Post to your Facebook.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ffacebook1591145306183?alt=media&token=4f9d7b26-4b37-4d69-85e5-c0406263f326',
      category: 'social'
    },
    {
      name: 'Gmail',
      explanation: 'Send emails from your Gmail.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgmail1591145349526?alt=media&token=bee66149-8d4d-45dc-aa17-d48c04e53c84',
      category: 'email'
    },
    {
      name: 'Google Calendar',
      explanation: 'Update your Google Calendar.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgoogle%20calendar1591145364898?alt=media&token=3637cc35-03df-4edd-81ea-72506a38ae19',
      category: 'calendars'
    },
    {
      name: 'Google Contacts',
      explanation: 'Add to your Google Contacts.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgoogle%20contacts1591145379261?alt=media&token=01127eca-5f91-46f9-80e3-6d96e7d519a3',
      category: 'sales'
    },
    {
      name: 'Google Docs',
      explanation: 'Update your Google Docs.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgoogle%20docs1591145394589?alt=media&token=003f135b-a6f7-4c01-a463-74d552fabb70',
      category: 'documents'
    },
    {
      name: 'Google Drive',
      explanation: 'Auto manage your Google Drive files.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgoogle%20drive1591145561745?alt=media&token=09546d9e-9ab4-4ab0-a35c-c65d55d3f7a1',
      category: 'storage'
    },
    {
      name: 'Google Forms',
      explanation: 'Manage your GForms.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgoogle%20forms1591145575486?alt=media&token=aa692f85-0a7a-4ec6-9ae0-f4fb75df83f7',
      category: 'forms'
    },
    {
      name: 'Google Tasks',
      explanation: 'Add to your GTasks.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2FGoogle%20Tasks1591147791780?alt=media&token=0669ac7b-ab1f-465e-9f9c-023d09b85055',
      category: 'tasks'
    },
    {
      name: 'Google Sheets',
      explanation: 'Add rows in your Sheets.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgoogle%20sheets1591145590643?alt=media&token=564795a1-a279-471f-9e2a-ca3b572d9fbe',
      category: 'sheets'
    },
    {
      name: 'Google GSuite',
      explanation: 'Manage your Gsuite.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fgsuite1591145336470?alt=media&token=26168f18-8e8f-4794-bcee-7998908ee88c',
      category: 'business'
    },
    {
      name: 'Hubspot',
      explanation: 'Create customers & tasks in your Hubspot.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fhubspot1591145607611?alt=media&token=83c0077f-c2ea-4b7a-903f-a54191c5e6da',
      category: 'sales'
    },
    {
      name: 'Instagram',
      explanation: 'Manage staff features of your Instagram.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Finstagram1591145621700?alt=media&token=fc30ddf9-068d-454f-bb99-1afba184bd7f',
      category: 'social'
    },
    {
      name: 'LinkedIn',
      explanation: 'Post to your LinkedIn.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Flinkedin1591145635495?alt=media&token=dff205bb-30b2-4804-a845-87fabd9fde5f',
      category: 'social'
    },
    {
      name: 'Mail Chimp',
      explanation: 'Create lists and campaigns in your MailChip.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmail%20chimp1591146099483?alt=media&token=38ee3b2c-9429-475d-b1f7-7ac99ba1a8c6',
      category: 'email'
    },
    {
      name: 'Messenger',
      explanation: 'Auto manage Messenger messages.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmessenger1591145322602?alt=media&token=a56a747f-5390-4849-8b38-e9db8fe7a112',
      category: 'social'
    },
    {
      name: 'Microsoft Excel',
      explanation: 'Add lines to spreadsheets.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmicrosoft%20excel1591145650812?alt=media&token=95fd6ad9-bef5-4ea4-8a8a-910d3e9d6916',
      category: 'sheets'
    },
    {
      name: 'Microsoft Outlook',
      explanation: 'Auto send emails from Outlook emails.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmicrosoft%20outlook1591145669382?alt=media&token=7d29e14c-def4-49d2-90bc-5188368e64ad',
      category: 'email'
    },
    {
      name: 'Microsoft 365',
      explanation: 'Link with Microsoft 365.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Foffice%203651591145683232?alt=media&token=b87e847c-24c8-44e1-b07e-cff8fa6cee61',
      category: 'business'
    },
    {
      name: 'Monday',
      explanation: 'Auto manage your projects through Monday.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fmonday1591147932464?alt=media&token=d9a7f18f-56a7-4dbc-a6ed-80c101951685',
      category: 'sales'
    },
    {
      name: 'Pipedrive',
      explanation: 'Manage users and sales through Pipedrive.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fpipedrive1591146117357?alt=media&token=fdb7d8ad-2ac4-4cb0-91f9-62a240b38e8d',
      category: 'sales'
    },
    {
      name: 'Quickbooks',
      explanation: 'Create customers & invoices automatically in your Quickbooks.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fquickbooks1591145697041?alt=media&token=e29c015b-b536-4cd7-8e8e-4e5ea9f3c5a2',
      category: 'accounting'
    },
    {
      name: 'Salesforce',
      explanation: 'Track users & update tasks in Salesforce.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fsalesforce1591145710100?alt=media&token=e42baca1-ec73-4ae3-9317-8ba8f47908e3',
      category: 'sales'
    },
    {
      name: 'Shopify',
      explanation: 'Link from & integrate into your Shopify website.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fshopify1591145723487?alt=media&token=dada26ba-82fa-47d4-b70a-938b229181e9',
      category: 'websites'
    },
    {
      name: 'Slack',
      explanation: 'Post events to your team Slack.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fslack1591145736710?alt=media&token=c5204551-4698-49dc-baaa-eaddec79b8c8',
      category: 'teams'
    },
    {
      name: 'Todoist',
      explanation: 'Add tasks & projects to your Todoist.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftodoist1591145763293?alt=media&token=14f046f0-239a-4d55-91a0-499015bae580',
      category: 'tasks'
    },
    {
      name: 'Toggl',
      explanation: 'Track time in your Toggl.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftoggl1591145775098?alt=media&token=1c9f6c20-3525-4810-a241-db7db45be921',
      category: 'tasks'
    },
    {
      name: 'Microsoft Teams',
      explanation: 'Post events to your Microsoft teams chat.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fteams1591147874379?alt=media&token=45e2d143-8697-427d-a37b-f98335c7fc06',
      category: 'teams'
    },
    {
      name: 'Teams',
      explanation: 'Post events to teams.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2FTeamwork1591147984110?alt=media&token=23204b53-2c50-42bb-98ab-ce1a07bac349',
      category: 'teams'
    },
    {
      name: 'Trello',
      explanation: 'Create boards, cards and lists in your Trello .',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftrello1591145790052?alt=media&token=49381422-a76d-44d3-800d-760df2de5f44',
      category: 'tasks'
    },
    {
      name: 'Twitter',
      explanation: 'Automatically post to your Twitter.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Ftwitter1591145819192?alt=media&token=0b1ce2b3-e0a1-4397-8ae1-8bab8ecf6745',
      category: 'social'
    },
    {
      name: 'Webflow',
      explanation: 'Embed & link from your webflow website.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fwebflow1591146131273?alt=media&token=f7c5d256-493c-4ce2-b4e3-fb83af6e2182',
      category: 'websites'
    },
    {
      name: 'Weebly',
      explanation: 'Embed & link from your weebly website.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fweebly1591146147607?alt=media&token=a24ccda1-fe12-4061-9bb4-222ee10ae66a',
      category: 'websites'
    },
    {
      name: 'WuFoo',
      explanation: 'Process app forms through WuFoo.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2FWuFoo1591148041987?alt=media&token=20b56ea8-2419-4ae5-a8d1-c7d1ee8f8fd1',
      category: 'forms'
    },
    {
      name: 'Wix',
      explanation: 'Embed & link from your wix website.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fwix1591146162439?alt=media&token=ea6ac464-45aa-41c2-9da4-b2e1b2e9a3c4',
      category: 'websites'
    },
    {
      name: 'Wordpress',
      explanation: 'Embed & link from your wordpress website.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fwordpress1591145832820?alt=media&token=d3de500b-f0c1-4887-964a-3a7a8fd347a2',
      category: 'websites'
    },
    {
      name: 'Xero',
      explanation: 'Create customers & invoices in your Xero.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fxero1591145846457?alt=media&token=5a0c7138-8991-4b1c-8d06-dd1934227738',
      category: 'accounting'
    },
    {
      name: 'Zoho',
      explanation: 'Process app forms through Zoho.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fzoho1591148116219?alt=media&token=4ad7ff95-63c0-4707-a9b0-b2479fa00a40',
      category: 'forms'
    },
    {
      name: 'Zoom',
      explanation: 'Auto create your Zoom meetings.',
      url: 'https://firebasestorage.googleapis.com/v0/b/hospappwebsite.appspot.com/o/media%2Fzoom1591145859243?alt=media&token=3605b37e-3f01-4aa9-ab35-9dc14f965671',
      category: 'teams'
    },
  ];
  integrationCategories: string[] = [];
  showIntegrations: any[] = [];
  showingCategory: string = null;

  featuresExplanatory = [
    {
      icon: 'cart',
      feature: 'Products',
      numberFeature: 0,
      numberGroups: 0,
      description: 'How many different "things" do you want to sell in your app? Each "thing" can have multiple options. <br><br> For example 1 type of TeeShirt would be 1 product and could have 4 size options, 4 fit options and 4 style options.',
    },
    {
      icon: 'calendar',
      feature: 'Bookables',
      numberFeature: 0,
      numberGroups: 0,
      description: 'How many different "things" can be booked in your app? Each "thing" could be a class, event or persons calendar. <br><br> Within each "class" bookable there can be multiple recurrances of class types (For example a "Womens Boxing Class" bookable could have a Monday, Tuesday and Wednesday night class within it). <br><br> Within each event there can be multiple ticket types (For example a "Boxing Event" bookable could have a standard, premium and VIP tickets). <br><br> Within each persons calendar there can be multiple appointment types (For example a "Boxing PT Session" bookable could have short, medium and long sessions).',
    },
    {
      icon: 'repeat',
      feature: 'Subscribables',
      numberFeature: 0,
      numberGroups: 0,
      description: 'How many different recurring payment plans do you need? <br><br> For example do your have a weekly membership for U18\'s and over 18\'s. Each would be a different "subscribable"',
    },
    {
      icon: 'star',
      feature: 'Reward Programs',
      numberFeature: 0,
      numberGroups: 0,
      description: 'How many different reward programs do you need? <br><br> For example you could have a program that allows users to collect points as they interact with the app. <br><br> As they collect points they can progress through levels and/or spent those points on discounts or products.',
    },
    {
      icon: 'list',
      feature: 'Forms',
      numberFeature: 0,
      numberGroups: 0,
      description: 'How many different forms fo you need? <br><br> A form is used to get the information you need from users. <br><br> For example a medical disclaimer form or a acceptance of terms and conditions form could be created according to your needs.',
    },
    {
      icon: 'pencil',
      feature: 'Content',
      numberFeature: 0,
      numberGroups: 0,
      description: 'How many pieces of content will you publish? Each piece of content would be an article you have written, video you have produced or audio you have recorded.',
    },
  ];


  constructor(public afs: AngularFirestore) {
    this.appProductsIndex = this.appProducts.filter(app => app.showInIndex);
    this.featuresIndex = this.appFeatures.filter(app => app.showInIndex);
    this.blankProduct = cloneDeep(this.brandObject);
    this.showIntegrations = cloneDeep(this.integrations);
    this.displayIndustries = cloneDeep(this.appProducts);
    for (const integration of this.integrations) {
      if (!this.integrationCategories.includes(integration.category)) {
        this.integrationCategories.push(integration.category);
      }
    }
    for (const testimonial of this.appTestimonials) {
      if (!this.testimonialOnePerOwner.find(it => it.name === testimonial.name)) {
        this.testimonialOnePerOwner.push(testimonial);
      }
    }
  }

  // select feature
  selectFeature(feature) {
    if (!this.selectedFeature) {
      this.selectedFeature = feature;
    } else if (this.selectedFeature && this.selectedFeature !== feature) {
      this.selectedFeature = feature;
    } else {
      this.selectedFeature = null;
    }
  }

  // filter integrations based on inputs of category and search
  searchIntegrations(event) {
    this.showIntegrations = [];
    this.showingCategory = null;
    if (event.detail.value) {
      this.showIntegrations = this.integrations.filter(i => i.name.toLowerCase().includes(event.detail.value) || i.name.includes(event.detail.value));
    } else {
      this.showIntegrations = cloneDeep(this.integrations);
    }
  }
  showCategory(category) {
    this.showIntegrations = [];
    if (!this.showingCategory || this.showingCategory !== category) {
      this.showingCategory = category;
      this.showIntegrations = this.integrations.filter(i => i.category === category);
    } else {
      this.showingCategory = null;
      this.showIntegrations = cloneDeep(this.integrations);
    }
  }
  showBrand(brand) {
    this.showIntegrations = [];
  }

  // filter products based on route and search
  filterProduct(name) {
    if (this.currentIndustry === name) {
      this.displayIndustries = cloneDeep(this.appProducts);
      this.currentIndustry = null;
    } else {
      this.displayIndustries = this.appProducts.filter(p => p.name === name);
      this.currentIndustry = name;
    }
  }

  filterLegitProductInterest(productParam: string) {
    const x = this.appProducts.find(availableProduct => productParam.includes(availableProduct.name.toLowerCase()));
    if (x) {
      this.filterProduct(x.name);
    } 
  }

  ifLegitSetProductInterest(productParam: string) {
    const x = this.appProducts.find(availableProduct => productParam.includes(availableProduct.name.toLowerCase()));
    if (x) {
      this.interest = x.name.toLowerCase();
      this.setBrandObjectVariables();
    } 
  }

  setBrandObjectVariables() {
    let matchingObjects: AppProduct[] = this.appProducts.filter(app => {
      if (app.name.toLowerCase() === this.interest) {
        return true;
      } else {
        return false;
      }
    });
    if (this.splitId) {
      const splitFiltered: AppProduct[] = matchingObjects.filter(app => app.splitOption === this.splitId);
      if (splitFiltered.length > 0) {
        matchingObjects = splitFiltered;
      }
    }
    if (matchingObjects.length > 0) {
      if (!this.set) {
        this.brandObject = matchingObjects[0];
        this.brandObject.features = this.brandObject.features.map(code => {
          const feature = this.appFeatures.find(f => f.code === code);
          if (feature) {
            return feature;
          } else {
            return false;
          }
        });
        this.brandObject.benefits = this.brandObject.benefits.map(code => {
          const benefit = this.appBenefits.find(b => b.code === code);
          if (benefit) {
            return benefit;
          } else {
            return false;
          }
        });
        this.brandObject.screenshots = this.brandObject.screenshots.map(code => {
          const screenshot = this.screenshots.find(s => s.code === code);
          if (screenshot) {
            return screenshot;
          } else {
            return false;
          }
        });
        this.brandObject.testimonials = this.brandObject.testimonials.map(code => {
          const testimonial = this.appTestimonials.find(t => t.code === code);
          if (testimonial) {
            return testimonial;
          } else {
            return false;
          }
        });
        if (this.brandObject.upfrontPricingId) {
          this.afs.doc('prices/prices/upfront/' + this.brandObject.upfrontPricingId).valueChanges().pipe(first()).subscribe((data: any) => {
            if (data) {
              this.brandObject.upfrontPricing = data;
            } 
          });
        }
        if (this.brandObject.subscriptionPricingId) {
          this.afs.doc('prices/prices/recurring/' + this.brandObject.subscriptionPricingId).valueChanges().pipe(first()).subscribe((data: any) => {
            if (data) {
              this.brandObject.subscriptionPricing = data;
            } 
          });
        }
        this.brandObject.testimonials = this.brandObject.testimonials.filter(x => x);
        this.brandObject.screenshots = this.brandObject.screenshots.filter(x => x);
        this.brandObject.benefits = this.brandObject.benefits.filter(x => x);
        this.brandObject.features = this.brandObject.features.filter(x => x);
      }
    }
    this.set = true;
  }

  
}
