import { MenuController } from '@ionic/angular';
import { UpfrontPaymentOption } from './../../interfaces/upfrontPaymentOption';
import { Injectable } from '@angular/core';
import { RecurringPaymentOption } from '../../interfaces/recurringPaymentOption';
import { SigninPage } from '../../pages/signin/signin.page';
import { SignupPage } from '../../pages/signup/signup.page';
import { ModalController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class DetailService {

  uid: string = null;
  attemptedRoute: string = null;

  selectedUpfront: UpfrontPaymentOption = null;
  selectedSubscription: RecurringPaymentOption = null;
  viewVouchers: string = null;

  signupMessage: string = null;
  signupCta: string = null;

  constructor(public modalCtrl: ModalController,
              public afAuth: AngularFireAuth,
              public menuCtrl: MenuController) { 
                this.afAuth.authState.subscribe(data => {
                  if (data && data.uid) {
                    this.uid = data.uid;
                  } else {
                    this.uid = null;
                  }
                });
              }

  async displaySignup(toggle: boolean, upfrontPrice: UpfrontPaymentOption = null, subscriptionPrice: RecurringPaymentOption = null, splitOption: string = null, message?: string, redirect?: string, cta?: string) {
    if (toggle) {
      await this.menuCtrl.toggle();
    }
    const modal = await this.modalCtrl.create({
      component: SignupPage,
      mode: 'ios',
      componentProps: {
        mode: 'customer',
        upfrontPricing: upfrontPrice,
        subscriptionPricing: subscriptionPrice,
        splitOption: splitOption,
        message: message,
        redirect: redirect,
        cta: cta
      }
    });
    modal.onDidDismiss().then(data => {
      if (data.role === 'alternate') {
        this.displaySignin(false);
      }
    });
    modal.present();
  }
  async displaySignin(toggle: boolean) {
    if (toggle) {
      await this.menuCtrl.toggle();
    }
    const modal = await this.modalCtrl.create({
      component: SigninPage,
      mode: 'ios',
      componentProps: {
        mode: 'customer',
      }
    });
    modal.onDidDismiss().then(data => {
      if (data.role === 'alternate') {
        this.displaySignup(false);
      }
    });
    modal.present();
  }
}
