import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  uid: string = null;
  stashedLogs: any[] = [];

  constructor(public gtmService: GoogleTagManagerService,
              public afs: AngularFirestore,
              public afAuth: AngularFireAuth) { 
                this.afAuth.authState.subscribe(user => {
                  if (this.uid === null && user !== null) {
                    this.uid = user.uid;
                    for (const log of this.stashedLogs) {
                      this.saveLog(log);
                    }
                    this.stashedLogs = [];
                  }
                  if (user && user.uid) {
                    this.uid = user.uid;
                  } else {
                    this.uid = null;
                  }
                });
              }

  sendEvent(data) {
    // const gtmTag: any = {
    //   event: data,
    //   data: data
    // };
    // this.gtmService.pushTag(gtmTag);
    // if (!this.uid) {
    //   gtmTag.time = new Date();
    //   this.stashedLogs.push(gtmTag);
    // } else {
    //   gtmTag.time = new Date();
    //   this.saveLog(gtmTag);
    // }
  }

  saveLog(log) {
    this.afs.collection('users/' + this.uid + '/logs').add(log);
  }
}
