import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-staff-index-cohort-apply',
  templateUrl: './staff-index-cohort-apply.component.html',
  styleUrls: ['./staff-index-cohort-apply.component.scss'],
})
export class StaffIndexCohortApplyComponent implements OnInit {

  cohorts: any[] = [];
  user: any = null;
  notFound: string[] = [];

  constructor(public afs: AngularFirestore,
              public modalCtrl: ModalController,
              public popoverController: PopoverController,
              public navParams: NavParams) { 
    this.user = this.navParams.get('user');
    this.afs.collection('cohorts', ref => ref.orderBy('index', 'asc')).valueChanges({idField: 'id'}).subscribe(cohorts => {
      this.cohorts = cohorts;
      this.user.cohorts.forEach(cohortId => {
        if (!this.cohorts.find(c => c.id === cohortId)) {
          this.notFound.push(cohortId);
        }
      });
    });
  }

  ngOnInit() {}

  inCohort(cohort): boolean {
    if (!this.user || !this.user.cohorts) {
      return false;
    }
    if (this.user.cohorts.includes(cohort.id)) {
      return true;
    } else {
      return false;
    }
  }

  toggleCohort(cohort) {
    if (!this.user.cohorts) {
      this.user.cohorts = [];
    }
    if (!this.inCohort(cohort)) {
      this.user.cohorts.push(cohort.id);
    } else {
      this.user.cohorts.splice(this.user.cohorts.indexOf(cohort.id), 1);
    }
  }

  removeNotFound() {
    for (const nfid of this.notFound) {
      this.user.cohorts.splice(this.user.cohorts.indexOf(nfid), 1);
    }
    this.save();
  }

  save() {
    this.afs.doc('users/' + this.user.uid).set({cohorts: this.user.cohorts}, {merge: true});
    this.modalCtrl.dismiss();
  }
}
