import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignedInGuard } from './guards/signed-in.guard';

const routes: Routes = [
  // homepage which also indexes apps by product
  { 
    path: '', 
    loadChildren: './pages/staff-tools/staff-tools.module#StaffToolsPageModule',
    canActivate: [SignedInGuard] 
  },
  {
    path: 'signin',
    loadChildren: './pages/sign/sign.module#SignPageModule'
  },
  {
    path: 'signup',
    loadChildren: './pages/sign/sign.module#SignPageModule'
  },
  // maintenance of developer accounts
  {
    path: 'developer-appstore',
    loadChildren: './pages/developer-appstore/developer-appstore.module#DeveloperAppstorePageModule',
  },
  {
    path: 'developer-playstore',
    loadChildren: './pages/developer-playstore/developer-playstore.module#DeveloperPlaystorePageModule',
  },
  {
    path: 'project-config/:pid',
    loadChildren: './pages/project-config/project-config.module#ProjectConfigPageModule'
  },
  // staff manager pages
  { 
    path: 'staff', 
    loadChildren: './pages/staff-tools/staff-tools.module#StaffToolsPageModule' 
  },
  {
    path: 'staff-email-manager',
    loadChildren: './pages/staff-email-manager/staff-email-manager.module#StaffEmailManagerPageModule'
  },
  { 
    path: 'staff/upload_sales', 
    loadChildren: './pages/staff-upload-sales-json/staff-upload-sales-json.module#StaffUploadSalesJsonPageModule' 
  },
  { 
    path: 'staff/users', 
    loadChildren: './pages/staff-index/staff-index.module#StaffIndexPageModule',
  },
  { 
    path: 'staff/users/:uid', 
    loadChildren: './pages/staff-customer-detail/staff-customer-detail.module#StaffCustomerDetailPageModule',
  },
  { 
    path: 'staff/users/:uid/logs', 
    loadChildren: './pages/staff-customer-detail-logs/staff-customer-detail-logs.module#StaffCustomerDetailLogsPageModule' 
  },
  { 
    path: 'staff/lessons', 
    loadChildren: './pages/staff-lesson-manager/staff-lesson-manager.module#StaffLessonManagerPageModule' 
  },
  { 
    path: 'staff/users/:uid/messages', 
    loadChildren: './pages/staff-support-manager/staff-support-manager.module#StaffSupportManagerPageModule' 
  },
  { 
    path: 'staffFeedbackManager', 
    loadChildren: './pages/staff-feedback-manager/staff-feedback-manager.module#StaffFeedbackManagerPageModule' 
  },
  { 
    path: 'staff/upfront', 
    loadChildren: './pages/staff-subscription-upfront-manager/staff-subscription-upfront-manager.module#StaffSubscriptionUpfrontManagerPageModule'
  },
  { 
    path: 'staff/subscriptions', 
    loadChildren: './pages/staff-subscription-recurring-manager/staff-subscription-recurring-manager.module#StaffSubscriptionRecurringManagerPageModule',
    canActivate: [SignedInGuard]
  },
  { 
    path: 'staff/media', 
    loadChildren: './pages/staff-media-uploader/staff-media-uploader.module#StaffMediaUploaderPageModule' 
  },
  { 
    path: 'staff/releases', 
    loadChildren: './pages/staff-releases-manager/staff-releases-manager.module#StaffReleasesManagerPageModule' 
  },
  { 
    path: 'staff/runners', 
    loadChildren: './pages/runners/runners.module#RunnersModule' 
  },
  { 
    path: 'staffSubscriptionUpfrontCrud', 
    loadChildren: './pages/staff-subscription-upfront-crud/staff-subscription-upfront-crud.module#StaffSubscriptionUpfrontCrudPageModule' 
  },
  { 
    path: 'staffSubscriptionRecurringCrud', 
    loadChildren: './pages/staff-subscription-recurring-crud/staff-subscription-recurring-crud.module#StaffSubscriptionRecurringCrudPageModule' 
  },
  { 
    path: 'staff/stripe/tax', 
    loadChildren: './pages/staff-tax-manager/staff-tax-manager.module#StaffTaxManagerPageModule' 
  },
  { 
    path: 'staff/stripe', 
    loadChildren: './pages/staff-stripe-index/staff-stripe-index.module#StaffStripeIndexPageModule' 
  },
  { 
    path: 'staff/stripe/products', 
    loadChildren: './pages/staff-subscription-product-manager/staff-subscription-product-manager.module#StaffSubscriptionProductManagerPageModule' 
  },
  { 
    path: 'staff/stripe/products/plans', 
    loadChildren: './pages/staff-subscription-plan-manager/staff-subscription-plan-manager.module#StaffSubscriptionPlanManagerPageModule' 
  },
  { 
    path: 'staff/training', 
    loadChildren: './pages/staff-training-manager/staff-training-manager.module#StaffTrainingManagerPageModule' 
  },
  {
    path: 'staff-cold-message',
    loadChildren: './pages/staff-cold-message/staff-cold-message.module#StaffColdMessagePageModule'
  },
  { 
    path: 'staff/blog', 
    loadChildren: './pages/content-manager/content-manager.module#ContentManagerPageModule' 
  },
  {
    path: 'staff/tasks',
    loadChildren: './pages/staff-tasks/staff-tasks.module#StaffTasksPageModule'
  },
  {
    path: 'staff/funnells',
    loadChildren: './pages/staff-tasks/staff-tasks.module#StaffTasksPageModule'
  },
  {
    path: 'staff/users/:uid/proposal',
    loadChildren: './pages/staff-proposal-crud/staff-proposal-crud.module#StaffProposalCrudPageModule'
  },
  {
    path: 'staff/terms-and-conditions',
    loadChildren: './pages/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsPageModule'
  },
  {
    path: 'staff/announcements',
    loadChildren:'./pages/staff-announcements-manager/staff-announcements-manager.module#StaffAnnouncementsManagerPageModule'
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
