import { NgModule, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "./interceptor/token.interceptor";
import { environment } from '../environments/environment';
import { MediaSelectorPage } from './pages/media-selector/media-selector.page';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { MarkdownModule, MarkdownModuleConfig } from 'ngx-markdown';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { StaffIndexCohortApplyComponent } from './pages/staff-index-cohort-apply/staff-index-cohort-apply.component';
import { SigninPageModule } from './pages/signin/signin.module';
import { SignupPageModule } from './pages/signup/signup.module';
import { ServiceWorkerModule } from '@angular/service-worker';

const f = environment.firebaseConfig;
const gtm = environment.gtm_id;

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot({
      mode: 'md'
    }),
    AngularFireModule.initializeApp(f),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxCsvParserModule,
    MonacoEditorModule.forRoot(),
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
    }),
    AppRoutingModule,
    HttpClientModule,
    SigninPageModule,
    SignupPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'googleTagManagerId', useValue: gtm },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: "windowObject", useValue: window},
  ],
  declarations: [
    AppComponent,
    MediaSelectorPage, 
    StaffIndexCohortApplyComponent
  ],
  entryComponents: [
    MediaSelectorPage, 
    StaffIndexCohortApplyComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
