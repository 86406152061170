import { AngularFirestore } from '@angular/fire/firestore';
import { ThemeService } from './../../services/theme/theme.service';
import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import {AlertController, LoadingController, ModalController, MenuController, Platform, ToastController} from "@ionic/angular";
import { Location } from '@angular/common';
import {AngularFireAuth} from "@angular/fire/auth";
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../../services/googleAnalytics/google-analytics.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss'],
})
export class SigninPage implements OnInit {

  signinUser: any = {
    email: null,
    password: null
  };

  constructor(public modalCtrl: ModalController,
              public themeService: ThemeService,
              public loadingCtrl: LoadingController,
              public menuCtrl: MenuController,
              public afAuth: AngularFireAuth,
              public location: Location,
              public router: Router,
              public platform: Platform,
              public googleAnalyticsService: GoogleAnalyticsService,
              public afs: AngularFirestore,
              public auth: AuthService,
              public toastController: ToastController,
              public alertCtrl: AlertController) { }

  ngOnInit() {
    this.googleAnalyticsService.sendEvent('open_login');
  }

  signup() {
    this.modalCtrl.dismiss({}, 'alternate');
  }

  async resetPassword() {
    const loading = await this.loadingCtrl.create({
      mode: 'ios'
    });
    loading.present();
    this.afAuth.sendPasswordResetEmail(this.signinUser.email)
      .then(async data => {
        await loading.dismiss();
        const toast = await this.toastController.create({
          message: 'Password reset email send.',
          duration: 1000,
        });
        toast.present();
      })
      .catch(async err => {
        console.log(err);
        await loading.dismiss();
        const toast = await this.toastController.create({
          message: 'Password reset email failed to send.',
          duration: 1000
        });
        toast.present();
      });
  }

  async login() {
    const loading = await this.loadingCtrl.create({
      mode: 'ios'
    });
    loading.present();
      this.afAuth.signInWithEmailAndPassword(this.signinUser.email, this.signinUser.password)
        .then((data) => {
          this.googleAnalyticsService.sendEvent('login');
          this.afs.doc('users/' + data.user.uid).valueChanges().pipe(first()).subscribe(async (index: any) => {
            if (this.auth.attemptedRoute) {
              await loading.dismiss();
              await this.modalCtrl.dismiss();
              this.router.navigateByUrl(this.auth.attemptedRoute);
            } else if (index.axipAgency) {
              await loading.dismiss();
              await this.modalCtrl.dismiss();
              this.router.navigate(['myagency']);
            } else if (index.axipStaff) {
              await loading.dismiss();
              await this.modalCtrl.dismiss();
              this.router.navigate(['staff']);
            } else {
              await loading.dismiss();
              await this.modalCtrl.dismiss();
              this.router.navigate(['resources']);
            }
            this.signinUser.email = null;
            this.signinUser.password = null;
          });
        })
        .catch(async err => {
          this.signinUser.password = null;
          const alert = await this.alertCtrl.create({
            header: 'Login Error',
            mode: 'ios',
            message: err.message,
            buttons: ['OK']
          });
          await alert.present();
          await loading.dismiss();
        });
  }


}
