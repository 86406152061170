import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  navbarlogo: string = '../assets/navlogo.png';

  constructor() { }
}
